
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import DesainType from "@/components/1crm/product/detail-order/desain-type.vue";
import PrintData from "@/components/1crm/product/detail-order/print-data.vue";
import ConfirmData from "@/components/1crm/product/detail-order/confirm.vue";
// import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";

export default defineComponent({
  name: "detailOrder",
  components: {
    DesainType,
    PrintData,
    ConfirmData,
    // UploadComponent,
  },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      tab: "#kt_tab_pane_2",
    };
  },
  // beforeRouteLeave(to, from, next) {
  //   const answer = window.confirm(
  //     "Do you really want to leave? you have unsaved    changes!"
  //   );
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Desain Kartu", ["Daftar Produk"]);
  },
  methods: {
    tabChange(value) {
      this.tab = value;
      console.log(value);
    },
  },
});
